<template>
  <thead class="table-header">
    <tr class="table-header__row">
      <th
        v-for="(column, i) in columns"
        :key="column.key ?? 'null' + i"
        class="header-cell"
        :class="{ 'header-cell--with-vertical-borders': withVerticalBorders }"
      >
        <div
          v-if="column.key"
          class="header-cell__content"
          :style="`justify-content:${
            column.justifyHeader ? column.justifyHeader : 'left'
          }`"
        >
          <span
            class="content__value"
            :class="[
              sortingByColumns && !!sortingByColumns[column.key]
                ? 'content__value--selected'
                : 'content__value--not-selected'
            ]"
            >{{
              column.countDistinct
                ? `${column.value} (${column.countDistinct})`
                : `${column.value}`
            }}</span
          >
          <i
            v-if="column.isSortable"
            class="content__icon"
            :class="[
              sortingByColumns &&
              sortingByColumns[column.key] === OrderValue.DESC
                ? 'icon-reverse-alphabet'
                : 'icon-alphabet',
              sortingByColumns && sortingByColumns[column.key] === null
                ? 'content__icon--not-selected'
                : 'content__icon--selected'
            ]"
            @click="updateColumnsSorting(column.key)"
          />
        </div>
      </th>
    </tr>
  </thead>
</template>

<script lang="ts">
import type { SortingByColumn } from '~/types/analytics'
import { OrderValue } from '~/types/cube'
import type { TableColumn } from '~/types/table'

export default {
  name: 'TableHeader',
  props: {
    columns: {
      type: Array as PropType<TableColumn[]>,
      required: true
    },
    sortingByColumns: {
      type: Object as PropType<SortingByColumn | null>,
      default: null
    },
    withVerticalBorders: {
      type: Boolean,
      default: true
    }
  },
  emits: ['updateColumnsSorting'],
  setup() {
    return { OrderValue }
  },
  methods: {
    updateColumnsSorting(column: string) {
      this.$emit('updateColumnsSorting', column)
    }
  }
}
</script>

<style lang="scss" scoped>
.table-header {
  &__row {
    color: $text-inverse;
  }
}

.header-cell {
  background-color: $bg-quinary;
  padding: $margin-small $margin-regular;
  vertical-align: middle;
  text-align: left;
  border-bottom: 1px solid $bg-primary;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  z-index: 1;

  &--with-vertical-borders {
    border: solid $bg-primary;
    border-width: 0 0 1px 1px;
  }

  &:first-child {
    border-left: 0;
  }

  &:last-child {
    border-right: 0;
  }

  &__content {
    display: flex;
    align-items: center;
    color: $text-primary;

    .content {
      &__value {
        display: inline-table;
        @include font-size($font-size-small);
        margin-right: 4px;

        &--selected {
          @include font-text($font-weight-medium);
        }

        &--not-selected {
          @include font-text($font-weight-book);
        }
      }

      &__icon {
        display: inline-table;
        @include font-icon;
        @include font-size(19px);
        cursor: pointer;

        &--selected {
          color: $text-primary;
        }

        &--not-selected {
          color: $text-tertiary;
        }
      }
    }
  }
}
</style>
